import React from 'react';

const TableCellFormatter = ({ column, value }) => {
  // Helper function to check if a value is empty
  const isEmpty = (val) => {
    return val === null || val === undefined || val === '';
  };

  // Helper function to check if a column is a weight column
  const isWeightColumn = (col) => {
    const weightColumns = ['weight', 'allocation', 'exposure'];
    return weightColumns.some(term => col.toLowerCase().includes(term));
  };

  // Helper function to check if a value is numeric
  const isNumeric = (val) => {
    if (typeof val === 'number') return true;
    if (typeof val !== 'string') return false;
    return !isNaN(parseFloat(val)) && isFinite(val);
  };

  // Helper function to format as currency
  const formatCurrency = (val) => {
    if (!isNumeric(val)) return '-';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(parseFloat(val));
  };

  // Helper function to format as percentage
  const formatPercentage = (val) => {
    if (!isNumeric(val)) return '-';
    const numValue = parseFloat(val);
    return new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(numValue *100 / 100); // Assuming the input is already in percentage form
  };

  // Check if empty before any other processing
  if (isEmpty(value)) {
    return <span>-</span>;
  }

  // Validate column input
  const columnName = typeof column === 'string' ? column.toLowerCase() : '';
  
  // Check for special column types
  const isTickerColumn = columnName.includes('ticker');
  const isAumColumn = columnName.includes('aum');

  // Handle different column types
  if (isTickerColumn) {
    return (
      <a 
        href={`https://etf.quantie.com/${value}.html`} 
        target="_blank" 
        rel="noopener noreferrer"
        className="text-blue-600 hover:text-blue-800"
      >
        {value}
      </a>
    );
  }

  if (isWeightColumn(columnName) && isNumeric(value)) {
    return <span>{formatPercentage(value)}</span>;
  }

  if (isAumColumn && isNumeric(value)) {
    return <span>{formatCurrency(value)}</span>;
  }

  // Default case: return original value
  return <span>{value?.toString() || '-'}</span>;
};

export default TableCellFormatter;