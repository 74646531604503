import React, { useState, useEffect, useRef } from 'react';
import Plot from 'react-plotly.js';
import TableCellFormatter from './TableCellFormatter';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.scss';
import Button from '@mui/material/Button';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import logo from './logo192.png';
import { FaHome,FaQuestion,FaGlobe, FaCalendar, FaArrowLeft,FaEnvelope, FaInfoCircle } from 'react-icons/fa';
const handleLogoClick = () => {
  const subject = 'Feedback for ETFAI';
  const body = 'Please provide your feedback here...';
  const mailtoLink = `mailto:info@quantie.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  window.location.href = mailtoLink;
};
const INSPIRATIONAL_QUOTES = [
  "The best way to predict the future is to create it. - Peter Drucker",
  "Investment in knowledge pays the best interest. - Benjamin Franklin",
  "The stock market is filled with individuals who know the price of everything, but the value of nothing. - Philip Fisher",
  "The four most dangerous words in investing are: 'this time it's different.' - Sir John Templeton",
  "The individual investor should act consistently as an investor and not as a speculator. - Ben Graham",
  "In investing, what is comfortable is rarely profitable. - Robert Arnott",
  "The biggest risk of all is not taking one. - Mellody Hobson",
  "The stock market is a device for transferring money from the impatient to the patient. - Warren Buffett",
  "It's not how much money you make, but how much money you keep, how hard it works for you, and how many generations you keep it for. - Robert Kiyosaki",
"Diversification is protection against ignorance. It makes little sense if you know what you are doing. - Warren Buffett",
  "The ETF is the greatest financial innovation of the 21st century. - Nate Most",
  "The most important quality for an investor is temperament, not intellect. - Warren Buffett",
  "The secret to investing is to be greedy when others are fearful and fearful when others are greedy. - Warren Buffett",
  "Know what you own, and know why you own it. - Peter Lynch",
  "The investor's chief problem – and even his worst enemy – is likely to be himself. - Benjamin Graham",
  "Risk comes from not knowing what you're doing. - Warren Buffett",
  "The best investment you can make is in yourself. - Warren Buffett",
  "The key to making money in stocks is not to get scared out of them. - Peter Lynch",
  "Time in the market beats timing the market. - Ken Fisher",
  "Compound interest is the eighth wonder of the world. - Albert Einstein",
  "The stock market is designed to transfer money from the active to the patient. - Warren Buffett",
  "The only investors who shouldn't diversify are those who are right 100% of the time. - Sir John Templeton",
  "Never invest in a business you cannot understand. - Warren Buffett",
  "The best way to measure your investing success is not by whether you're beating the market but by whether you've put in place a financial plan and a behavioral discipline that are likely to get you where you want to go. - Benjamin Graham",
  "Investing should be more like watching paint dry or watching grass grow. If you want excitement, take $800 and go to Las Vegas. - Paul Samuelson",
  "The market is a device for transferring money from the impatient to the patient. - Warren Buffett",
  "In the short run, the market is a voting machine. In the long run, it's a weighing machine. - Benjamin Graham",
  "Wide diversification is only required when investors do not understand what they are doing. - Warren Buffett",
  "The most contrarian thing of all is not to oppose the crowd but to think for yourself. - Peter Thiel"
];

function App() {
    const [recording, setRecording] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);

    const [loadingProgress, setLoadingProgress] = useState(0);    const [showAddTrainingDataModal, setShowAddTrainingDataModal] = useState(false);
    const [trainingDataStatus, setTrainingDataStatus] = useState('');
    const [currentTrainingData, setCurrentTrainingData] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newQuestion, setNewQuestion] = useState('');
    const [loading, setLoading] = useState(false);
    const [questionHistory, setQuestionHistory] = useState([]);
    const [newTrainingData, setNewTrainingData] = useState({
        training_data_type: 'question',
        question: '',
        content: '',
    });
    const [trainingData, setTrainingData] = useState([]);
    // const [darkMode, setDarkMode] = useState(false);
    const [selectedTrainingExample, setSelectedTrainingExample] = useState(null);
    // const [audioFile, setAudioFile] = useState(null);
    const [audioFile, setAudioFile] = useState(null);
    const [sampleQuestions, setSampleQuestions] = useState([]);
   const [currentQuote, setCurrentQuote] = useState('');

   const getRandomQuote = () => {
    const randomIndex = Math.floor(Math.random() * INSPIRATIONAL_QUOTES.length);
    return INSPIRATIONAL_QUOTES[randomIndex];
   }; 
  useEffect(() => {
    const fetchSampleQuestions = async () => {
      try {
        const response = await fetch('/api/v0/generate_questions');
        const data = await response.json();
        setSampleQuestions(data.questions);
      } catch (error) {
        console.error('Error fetching sample questions:', error);
      }
    };
  
    fetchSampleQuestions();
  }, []);
   const toggleMobileMenu = () => {
  setIsMobileMenuOpen(!isMobileMenuOpen);
     };
  const fetchSampleQuestions = async () => {
    try {
      const response = await fetch('/api/v0/generate_questions');
      const data = await response.json();
      setSampleQuestions(data.questions);
    } catch (error) {
      console.error('Error fetching sample questions:', error);
    }
  };

  useEffect(() => {
    getQuestionHistory();
    getTrainingData();
  }, []);
  const sendQuestion = async (question) => {
    setLoading(true);
    setLoadingProgress(0);
    setSampleQuestions([]); 
    const userTimestamp = new Date().getTime();
    setMessages((prevMessages) => [...prevMessages, { sender: 'user', text: question, timestamp: userTimestamp }]);
    setNewQuestion('');
    try {
      const totalSteps = 4;
      let completedSteps = 0;
      const response = await fetch(`/api/v0/generate_sql?question=${encodeURIComponent(question)}`);
      const data = await response.json();
      const id = data.id;
      const generatedSQL = data.text;
  
      completedSteps++;
      setLoadingProgress((completedSteps / totalSteps) * 100);
      const sqlResponse = await fetch(`/api/v0/run_sql?id=${id}`);
      const sqlData = await sqlResponse.json();
  
      if (sqlData.type === 'error') {
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: 'bot', error: sqlData.error },
        ]);
        setLoading(false);
        return;
      }
  
      const parsedDF = JSON.parse(sqlData.df.replace(/\bNaN\b/g, '"NaN"'));
  
      const dfTimestamp = new Date().getTime();
      setMessages((prevMessages) => [...prevMessages, { sender: 'bot', text: '', df: parsedDF, id, timestamp: dfTimestamp, loading: true }]);
      completedSteps++;
      setLoadingProgress((completedSteps / totalSteps) * 100);
      const figureResponse = await fetch(`/api/v0/generate_plotly_figure?id=${id}`);
      const figureData = await figureResponse.json();
  
      const figureTimestamp = new Date().getTime();
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'bot', text: '', fig: figureData.fig, id, timestamp: figureTimestamp, loading: true },
      ]);
      completedSteps++;
      setLoadingProgress((completedSteps / totalSteps) * 100);
      const summaryResponse = await fetch(`/api/v0/generate_summary?id=${id}`);
      const summaryData = await summaryResponse.json();
  
      const summaryTimestamp = new Date().getTime();
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'bot', text: summaryData.text, id, timestamp: summaryTimestamp, loading: false },
      ]);
      completedSteps++;
      setLoadingProgress((completedSteps / totalSteps) * 100);
      const totalProcessingTime = summaryTimestamp - userTimestamp;
      let elapsedTime = 0;
      const updateProgress = () => {
        elapsedTime += 100;
        const progress = Math.min((elapsedTime / totalProcessingTime) * 100, 100);
        setLoadingProgress(progress);
        if (elapsedTime < totalProcessingTime) {
          setTimeout(updateProgress, 100);
        } else {
          // Fetch new sample questions after loading progress reaches 100%
          const fetchFollowupQuestions = async () => {
            const followupQuestionsResponse = await fetch(`/api/v0/generate_followup_questions?id=${id}`);
            const followupQuestionsData = await followupQuestionsResponse.json();
            setSampleQuestions(followupQuestionsData.questions);
          };
          fetchFollowupQuestions();
        }
      };
      updateProgress();
  
      setCurrentTrainingData({
        question: question,
        sql: generatedSQL,
      });
  
      setLoading(false);
      getQuestionHistory();
    } catch (error) {
      setMessages((prevMessages) => [...prevMessages, { sender: 'bot', error: error.message }]);
      setLoading(false);
    }
  };

  const handleAddTrainingData = async () => {
    if (currentTrainingData) {
      await fetch('/api/v0/train', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(currentTrainingData),
      });

      getTrainingData();
    //   setShowAddTrainingDataModal(false);
    }
  };

    const downloadCSV = async (id) => {
        const response = await fetch(`/api/v0/download_csv?id=${id}`);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'data.csv';
        a.click();
        window.URL.revokeObjectURL(url);
    };

  const getQuestionHistory = async () => {
    const response = await fetch('/api/v0/get_question_history');
    const data = await response.json();
    setQuestionHistory(data.questions);
  };

  const loadQuestion = async (id) => {
    setMessages([]);
    setLoading(true);

    const response = await fetch(`/api/v0/load_question?id=${id}`);
    const data = await response.json();

    setMessages([
      { sender: 'user', text: data.question },
      { sender: 'bot', text: 'SQL:', sql: data.sql, id },
      { sender: 'bot', text: 'Results:', df: data.df, id },
      { sender: 'bot', text: 'Follow-up questions:', followup_questions: data.followup_questions, id },
    ]);

    setLoading(false);
  };

  const getTrainingData = async () => {
    const response = await fetch('/api/v0/get_training_data');
    const data = await response.json();
    setTrainingData(JSON.parse(data.df));
  };

  const addTrainingData = async () => {
    try {
      const trainingData = {
        question: newTrainingData.question,
        sql: newTrainingData.training_data_type === 'sql' ? newTrainingData.content : undefined,
        ddl: newTrainingData.training_data_type === 'ddl' ? newTrainingData.content : undefined,
        documentation: newTrainingData.training_data_type === 'documentation' ? newTrainingData.content : undefined,
      };

      const response = await fetch('/api/v0/train', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(trainingData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }

      setNewTrainingData({
        training_data_type: 'question',
        question: '',
        content: '',
      });
      getTrainingData();
    } catch (error) {
      alert('Error adding training data: ' + error.message);
    }
  };

  const removeTrainingData = async (id) => {
    if (window.confirm('Are you sure you want to remove this training data?')) {
      try {
        await fetch('/api/v0/remove_training_data', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id }),
        });
        getTrainingData();
      } catch (error) {
        alert('Error removing training data: ' + error.message);
      }
    }
  };

  const messagesContainerRef = useRef(null);
  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
    setTrainingDataStatus('');
  }, [messages]);

    useEffect(() => {
      setCurrentQuote(getRandomQuote());
	}, []);
  
  const showTrainingExampleContent = (example) => {
    setSelectedTrainingExample(example);
  };

  const handleReset = () => {
    setMessages([]);
    setNewQuestion('');
    setLoading(false);
    setQuestionHistory([]);
    setNewTrainingData({
        training_data_type: 'question',
        question: '',
        content: '',
    });
  };
  let audioChunks = [];
  
  const startRecording = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.error('Media Devices not supported');
      return;
    }
  
    // Check if we are currently recording
    if (recording) {
      // Stop the recording
      mediaRecorder.stop();
      mediaRecorder.stream.getTracks().forEach(track => track.stop()); // Ensure the stream is properly stopped
      setRecording(false); // Update recording state
    } else {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const newRecorder = new MediaRecorder(stream);
  
        newRecorder.ondataavailable = (event) => {
          // Handle recorded data
          if (event.data.size > 0) {
            const audioBlob = new Blob([event.data], { type: 'audio/wav' });
            const formData = new FormData();
            formData.append('file', audioBlob, 'recording.wav');
  
            fetch('/api/speech_to_text', {
              method: 'POST',
              body: formData,
            })
              .then(response => response.json())
              .then(data => {
                sendQuestion(data.text);
              })
              .catch(error => {
                console.error('Error converting speech to text:', error);
              });
          }
        };
  
        // Update the state with the new MediaRecorder instance
        setMediaRecorder(newRecorder);
        newRecorder.start(); // Start recording
        setRecording(true); // Update recording state
      } catch (error) {
        console.error('Error accessing microphone:', error);
        setRecording(false);
      }
    }
  };

/*
  const startRecording = async () => {
    console.log('startRecording function called');
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.error('Media Devices not supported');
      return;
    }
  
    if (recording) {
      console.log('Stopping recording');
      mediaRecorder.stop();
      mediaRecorder.stream.getTracks().forEach(track => track.stop());
      setRecording(false);
    } else {
      try {
        console.log('Starting new recording');
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const newRecorder = new MediaRecorder(stream);
  
        newRecorder.ondataavailable = (event) => {
          console.log('Data available from recorder', event.data.size);
          if (event.data.size > 0) {
            const audioBlob = new Blob([event.data], { type: 'audio/wav' });
            const formData = new FormData();
            formData.append('file', audioBlob, 'recording.wav');
  
            console.log('Sending audio to server');
            fetch('/api/speech_to_text', {
              method: 'POST',
              body: formData,
            })
              .then(response => response.json())
              .then(data => {
                console.log('Received transcription:', data.text);
                sendQuestion(data.text);
              })
              .catch(error => {
                console.error('Error converting speech to text:', error);
              });
          }
        };
  
        setMediaRecorder(newRecorder);
        newRecorder.start();
        setRecording(true);
        console.log('Recording started');
      } catch (error) {
        console.error('Error accessing microphone:', error);
        setRecording(false);
      }
    }
  };

  */

  const onAudioFileChange = (event) => {
    setAudioFile(event.target.files[0]);
  };
  const sendAudio = async () => {
    if (audioFile) {
      const formData = new FormData();
      formData.append('file', audioFile);
  
      try {
        const response = await fetch('/api/speech_to_text', {
          method: 'POST',
          body: formData,
        });
        const data = await response.json();
        sendQuestion(data.text);
      } catch (error) {
        console.error('Error converting speech to text:', error);
      }
    }
  };

  const [isFlipped, setIsFlipped] = useState(false);

  const handleHeaderClick = () => {
    setIsFlipped(!isFlipped);
  };
  
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const encodedQuery = queryParams.get('q');
    if (encodedQuery) {
      handleEncodedQuery(encodedQuery);
    }
  }, []);

  const handleEncodedQuery = async (encodedQuery) => {
    try {
      const response = await fetch(`/api/v0/decode_query/${encodedQuery}`);
      const data = await response.json();
      if (data.type === "sql") {
          // Instead of setting the SQL as the question, we set the original question
          setNewQuestion(data.question);
          // Then we process this question as if it was entered normally
          sendQuestion(data.question);
      } else {
        console.error('Error processing encoded query:', data.error);
      }
    } catch (error) {
      console.error('Error processing encoded query:', error);
    }
  };

  const generateShareableLink = async () => {
    if (!newQuestion) {
      alert("Please enter a question first.");
      return;
    }

    try {
      const response = await fetch('/api/v0/encode_query', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ question: newQuestion }),
      });
      const data = await response.json();
      if (data.encoded_query) {
        const shareableUrl = `${window.location.origin}?q=${data.encoded_query}`;
        navigator.clipboard.writeText(shareableUrl);
        alert("Your link has been copied to the clipboard!");
      } else {
        console.error('Error generating shareable link:', data.error);
      }
    } catch (error) {
      console.error('Error generating shareable link:', error);
    }
  };

  return (
<div className={`App`}>
   <header className="app-header">
  <div className="header-left">
    <div className="logo-container">
      <img src={logo} alt="Logo" className="app-logo" onClick={handleLogoClick} />
      <h1 className="company-name">ETF AI</h1>
    </div>
  </div>
  <div className="header-right">
    <button className="menu-toggle" onClick={toggleMobileMenu}>
      <i className="fas fa-bars"></i>
    </button>
    <div className={`header-links ${isMobileMenuOpen ? 'open' : ''}`}>
      <a href="https://prod.quantie.com/" target="_self" rel="noopener noreferrer" onClick={() => setIsMobileMenuOpen(false)}>
        <FaHome className="icon" /> Home
      </a>
      <a href="faq.html" target="_blank" rel="noopener noreferrer" onClick={() => setIsMobileMenuOpen(false)}>
        <FaQuestion className="icon" /> FAQ
      </a>
      <a href="https://share.hsforms.com/1hCu37EsKRTSJiBWkwcj50wd5xe8" target="_blank" rel="noopener noreferrer" onClick={() => setIsMobileMenuOpen(false)}>
        <FaEnvelope className="icon" /> Subscribe 
      </a> 
      <a href="disclaimer.html" target="_blank" rel="noopener noreferrer" onClick={() => setIsMobileMenuOpen(false)}>
        <FaInfoCircle className="icon" />Disclaimer 
      </a>
      <a href="https://www.quantie.com/" target="_blank" rel="noopener noreferrer" onClick={() => setIsMobileMenuOpen(false)}>
        <FaArrowLeft className="icon" /> Back
      </a>
    </div>
  </div>
</header>     
<main className="main-content">
        <div className="chat-container">
	  <div className="container mt-4">
                {}
                <div className="row">
                <div className="col-md-8">
  <div className="card chat-container mb-4" ref={messagesContainerRef}>
    <div className="card-body">
      {messages.length === 0 ? (
        <div className="empty-state">
    <p className="welcome-quote">{currentQuote}</p>
    <p className="start-prompt">Ask a question to get started!</p> 
	      </div>
      ) : (
        <div className="messages-container">
                            {messages.map((message, index) => (
                              <div key={index} className={`message ${message.sender} ${message.fig && 'chat-chart-container'}`}>
                                <div className="message-text">
                                  {message.text && (
                                    <div className="collapse-container">
                                      <div className="collapse" id={`summary-${index}`}>
                                        <p>{message.text}</p>
                                      </div>
                                    </div>
                                  )}
                                  {message.fig && (
  <div className="collapse-container">
    <div className="collapse" id={`figure-${index}`}>
      <div className="chat-chart-container">
        <Plot data={JSON.parse(message.fig).data} layout={JSON.parse(message.fig).layout} />
      </div>
    </div>
    <div className='add-train-buttons'>
      <p>Add training data</p>
      <Button className="download-btn add-button" variant="contained" endIcon={<AddIcon />} onClick={() => {
        handleAddTrainingData();
        setTrainingDataStatus('Training data added');
      }}>
        Yes
      </Button>
      <Button className="download-btn" variant="contained" endIcon={<RemoveIcon />} onClick={() => {
        setTrainingDataStatus('Training data not added');
      }}>
        No
      </Button>
    </div>
    {trainingDataStatus && <p><CheckCircleIcon/>{trainingDataStatus}</p>}
  </div>
)}
                                  {message.df && (
  <div className="collapse-container">
    <div className="collapse" id={`table-${index}`}>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              {Object.keys(message.df[0]).map((column) => (
                <th key={column}>{column}</th>
              ))}
            </tr>
          </thead>
          <tbody>
          {message.df.map((row, rowIndex) => (
  <tr key={rowIndex}>
    {Object.keys(row).map(column => (
      !column.toLowerCase().includes('etfg') && (
        <td key={column}>
          <TableCellFormatter column={column} value={row[column]} />
        </td>
      )
    ))}
  </tr>
))}
          </tbody>
        </table>
      </div>
      <Button className="download-btn" variant="contained" endIcon={<SimCardDownloadIcon />} onClick={() => {
        downloadCSV(message.id)
      }}>
        Download CSV
      </Button>
    </div>
  </div>
)}
                                  {/*message.sql && (
                                    <div className="collapse-container">
                                      <div className="collapse" id={`sql-${index}`}>
                                        <pre>{message.sql}</pre>
                                      </div>
                                    </div>
                                  )*/}
 {message.error && <p className="error-message">Error: {message.error}</p>}
 </div>
 <div className="message-timestamp">
 {message.sender === 'bot' && message.timestamp ? `Processing time: ${(message.timestamp - messages[index - 1].timestamp) / 1000} seconds` : ''}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
 {sampleQuestions.length > 0 && (
  <div className="sample-questions">
    {sampleQuestions.map((question, index) => (
      <button
        key={index}
        className="btn btn-outline-secondary"
        onClick={() => sendQuestion(question)}
      >
        {question}
      </button>
    ))}
  </div>
)}
                    <div className="input-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      value={newQuestion}
                      onChange={(e) => setNewQuestion(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          sendQuestion(newQuestion);
                        }
                      }}
                      placeholder="Type your question here..."
                      aria-label="Question input"
                    />
                    
	  	<div className="button-group">
                    <button className="btn btn-custom" type="button" onClick={startRecording}>
                      <i className={`fas ${recording ? 'fa-stop' : 'fa-microphone'}`}></i>
                    </button>
                    {loading ? (
                      <button className="btn btn-loading" type="button" disabled>
                        <div className="spinner"></div>
                        <span className="loading-message">Loading... {Math.round(loadingProgress)}%</span>
                      </button>
                    ) : (
                      <button className="btn btn-custom" type="button" onClick={handleReset}>
                        <i className="fas fa-rotate-left"></i>
                      </button>
                    )}
                    <button className="btn btn-custom" type="button" onClick={() => sendQuestion(newQuestion)}>
                      <i className="fas fa-paper-plane"></i>
                    </button>
                    <button className="btn btn-custom" type="button" onClick={generateShareableLink}>
                     <i className="fas fa-share-alt"></i>
                    </button>
                  </div>
	        	</div>
                  </div>
                  <div className='debug-only'>
                    <div className="col-md-4">
                      <div className="accordion" id="side-panel">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="question-history-header">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#question-history-content"
                              aria-expanded="true"
                              aria-controls="question-history-content"
                            >
                              Question History
                            </button>
                          </h2>
                          <div id="question-history-content" className="accordion-collapse collapse show" aria-labelledby="question-history-header">
                            {questionHistory.length === 0 ? (
                              <div className="accordion-body empty-state">
                                <i className="fas fa-history"></i>
                                <p>No questions asked yet.</p>
                              </div>
                            ) : (
                              <div className="accordion-body">
                                <ul className="list-group">
                                  {questionHistory.map((question, index) => (
                                    <li key={index} className="list-group-item" onClick={() => loadQuestion(question.id)}>
                                      {question.question}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
  
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="training-data-header">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#training-data-content"
                              aria-expanded="false"
                              aria-controls="training-data-content"
                            >
                              Training Data
                            </button>
                          </h2>
                          <div id="training-data-content" className="accordion-collapse collapse" aria-labelledby="training-data-header">
                            <div className="accordion-body">
                              <form onSubmit={(e) => { e.preventDefault(); addTrainingData(); }}>
                                <div className="mb-3">
                                  <label htmlFor="training-data-type" className="form-label">Data Type:</label>
                                  <select
                                    id="training-data-type"
                                    className="form-select"
                                    value={newTrainingData.training_data_type}
                                    onChange={(e) => setNewTrainingData({ ...newTrainingData, training_data_type: e.target.value })}
                                  >
                                    <option value="question">Question</option>
                                    <option value="sql">SQL</option>
                                    <option value="ddl">DDL</option>
                                    <option value="documentation">Documentation</option>
                                  </select>
                                </div>
                                <div className="mb-3">
                                  <label htmlFor="question-input" className="form-label">Question:</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="question-input"
                                    value={newTrainingData.question}
                                    onChange={(e) => setNewTrainingData({ ...newTrainingData, question: e.target.value })}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label htmlFor="content-input" className="form-label">Content:</label>
                                  <textarea
                                    className="form-control"
                                    id="content-input"
                                    rows="3"
                                    value={newTrainingData.content}
                                    onChange={(e) => setNewTrainingData({ ...newTrainingData, content: e.target.value })}
                                  ></textarea>
                                </div>
                                <button type="submit" className="btn btn-custom">Add</button>
                              </form>
                              <hr />
                              {trainingData.length === 0 ? (
                                <div className="empty-state">
                                  <i className="fas fa-database"></i>
                                  <p>No training data yet.</p>
                                </div>
                              ) : (
                                <table className="table table-striped">
                                  <thead>
                                    <tr>
                                      <th>Type</th>
                                      <th>Question</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {trainingData.map((example) => (
                                      <tr key={example.id}>
                                        <td>
                                          <i className={`fas ${
                                            example.training_data_type === 'question' ? 'fa-question-circle' :
                                            example.training_data_type === 'sql' ? 'fa-database' :
                                            example.training_data_type === 'ddl' ? 'fa-table' : 'fa-book'
                                          }`}></i>
                                        </td>
                                        <td onClick={() => showTrainingExampleContent(example)}>{example.question}</td>
                                        <td>
                                          <button className="btn btn-link text-danger" onClick={() => removeTrainingData(example.id)}>
                                            <i className="fas fa-trash"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {selectedTrainingExample && (
                      <div className="modal modal-sheet d-block" tabIndex="-1" role="dialog" id="training-example-modal">
                        <div className="modal-dialog" role="document">
                          <div className="modal-content rounded-4 shadow">
                            <div className="modal-header border-bottom-0">
                              <h5 className="modal-title">Training Example Content</h5>
                              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                onClick={() => setSelectedTrainingExample(null)}></button>
                            </div>
                            <div className="modal-body py-0">
                              <pre>{selectedTrainingExample.content}</pre>
                            </div>
                            <div className="modal-footer flex-column align-items-stretch w-100 gap-2 pb-3 border-top-0">
                              <button type="button" className="btn btn-lg btn-secondary" data-bs-dismiss="modal"
                                onClick={() => setSelectedTrainingExample(null)}>Close</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {showAddTrainingDataModal && (
                      <div className="modal modal-sheet d-block debug-only" tabIndex="-1" role="dialog" id="add-training-data-modal">
                        <div className="modal-dialog" role="document">
                          <div className="modal-content rounded-4 shadow">
                            <div className="modal-header border-bottom-0">
                              <h5 className="modal-title">Add Training Data</h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setShowAddTrainingDataModal(false)}
                              ></button>
                            </div>
                            <div className="modal-body py-0">
                              <p>Do you want to add this query as training data?</p>
                            </div>
                            <div className="modal-footer flex-column align-items-stretch w-100 gap-2 pb-3 border-top-0">
                              <button
                                type="button"
                                className="btn btn-lg btn-primary"
                                onClick={handleAddTrainingData}
                              >
                                Yes, add as training data
                              </button>
                              <button
                                type="button"
                                className="btn btn-lg btn-secondary"
                                data-bs-dismiss="modal"
                                onClick={() => setShowAddTrainingDataModal(false)}
                              >
                                No, don't add
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
	  </div>
	  </main>
      </div>
  );
}
  export default App;
